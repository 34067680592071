@import "../../fonts/euclid_circular_a/stylesheet.css";

* {
  font-family: "euclid_circular_aregular", monospace;
}

.alert {
  background-color: #c0a3f4 !important;
  font-size: 16px;
  color: white;
}

.alert-login {
  position: absolute;
  right: 48%;
  width: 40%;
}

.content-wrapper {
  min-height: auto !important;
}

.login-box {
  width: 40%;
  float: right;
  margin: 0 3rem 0;
}

.login-head {
  color: white;
  font-weight: 500;
  font-size: 20px;

  &__with_padding {
    padding: 0 15px;
  }
}

.display_block {
  display: block;
}

.display_inline_block {
  display: inline-block;
}

.variableWidth {
  width: 100%;
}

a {
  color: white;
}

a:hover {
  color: #c2a5f6;
}

a.tou_link {
  color: #e1d4fb;
  text-decoration: underline;
}

a.tou_link:hover {
  color: #c2a5f6;
}

#ios_device_message {
  background-color: #c0a3f4 !important;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  width: 75%;
  position: fixed !important;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.close_ios_message {
  position: absolute;
  right: 10px;
  top: 5px;
}

#ios_device_message i {
  margin-right: 8px;
  color: grey;
  display: none;
}

#ios_device_message p {
  margin: 0 1rem 0 0;
}

/* Customización del checkbox label (el container) */
.checkbox_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Ocultar el checkbox por defecto del navegador */
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Creamos un checkbox personalizado */
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: black;
  border: solid #c2a5f6 2px;
  border-radius: 0.4rem;
}

/* Añadir un fondo al hacer hover */
.checkbox_container:hover input ~ .checkmark {
  background-color: #e1d4fb;
}

/* Poner un fondo al hacer check en el checkbox */
.checkbox_container input:checked ~ .checkmark {
  background-color: #33205b;
  border: solid #33205b 2px;
}

/* Creamos el checkmark(tic interior del checkbox, oculto cuando no está checkado) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Mostrar el checkmark cuando está checkeado */
.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

/* Damos estilos al checkmark */
.checkbox_container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.twync_logo_image_box {
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.twync_logo_image {
  width: 81.5vw;
  position: relative;
  top: 20px;
  z-index: -1;
}

.twync_logo_image_mobile {
  display: none;
}

.remember_me_line {
  color: white;
  font-size: 12px;
  font-weight: 500;

  .checkbox_text {
    position: relative;
    right: 10px;
  }
}

.login_registration_btn {
  background-color: #41ffb4;
  border-radius: 10px;
}

.social_login_buttons_box {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.social_login_buttons_box .btn {
  width: 48%;
  border-radius: 10px;
}


.login_actions_box {
  display: flex;
  justify-content: space-between;

  button {
    width: 48% !important;
    height: 40px;
  }
}

.login_separator_hr {
  border-top: 5px solid white;
  border-radius: 5px;
  margin: 32px 0;
}

@media (max-width: 1230px) {
  .social_login_buttons_box {
    flex-direction: column;
  }

  .social_login_buttons_box .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .twync_logo_image {
    max-width: 80vw;
  }

  .login_actions_box button {
    width: 100% !important;
  }
}

@media (max-width: 850px) {
  .alert-login {
    position: static;
    width: 100%;

    .close {
      position: absolute;
      top: 25px;
      right: 0;
    }
  }

  .login-box {
    width: 85%;
    margin: 0 7.5% 0;
    position: relative;
    top: 10px;
  }

  .social_login_buttons_box {
    flex-direction: row;
  }

  .social_login_buttons_box .btn {
    width: 48%;
  }

  .twync_logo_image_box {
    position: relative;
    bottom: 75px;
  }

  .twync_logo_image {
    max-width: 80vw;
    right: 0;
    top: 0;
  }

  .login_actions_box button {
    width: 48% !important;
  }
}

@media (max-width: 425px) {
  #ios_device_message {
    margin: 0;
    bottom: 20px;
    width: 75%;
  }
  .alert-login {
    position: absolute;
    right: 0;
    top: 3px;
    width: 100%;
    height: 60%;
    z-index: 50;
    padding: 12px 35px;

    p {
      position: relative;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }

    .close {
      position: absolute;
      top: 0;
      right: -5px;
    }
  }
  .twync_logo_image_box {
    position: static;
  }

  .twync_logo_image {
    display: none;
  }
  .twync_logo_image_mobile {
    display: inline;
    position: relative;
    bottom: 20px;
    width: 85vw;
  }

  .login_actions_box button {
    width: 100% !important;
  }

  .flex_form_group {
    gap: 0;
  }

  .social_login_buttons_box {
    flex-direction: column;
  }

  .social_login_buttons_box .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 380px) {
  #ios_device_message {
    bottom: 8px;
    width: 85%;
  }
}

@media (max-width: 325px) {
  #ios_device_message {
    width: 95%;
  }
}

