.display_none {
  display: none;
}

.form-group label {
  display: inline-block !important;
}

.form-group label.display_block {
  display: block !important;
}

.tooltip_icon {
  color: #543684;
  opacity: .8;
}

.card_title {
  margin: 30px 0 0 30px;
  font-weight: 800;
}

.modal {
  z-index: 3000;
}

.modal-dialog {
  top: 10%;
}

.flex_form_group {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.form-group label {
  display: block;
}

.flex_child {
  width: 100%;
}

.display_block {
  display: block;
}

.title_help_box {
  margin-left: 30px;
}

.help-text {
  color: #a1a1a1;
  margin-bottom: 0;
}

.help-text.card_title_help {
  font-weight: 700;
  font-size: 1.2rem;
}

.btn_send_box {
  padding-bottom: 24px;
}

.btn_send_box .btn {
  margin-right: 8px;
}

.select2-selection.select2-selection--multiple, .select2-selection.select2-selection--single {
  border: 1px solid #ced4da;
}

.select2_input_validation_error .select2-selection.select2-selection--multiple, .select2_input_validation_error .select2-selection.select2-selection--single {
  border: solid red 1px;
}

.select2-selection.select2-selection--single {
  height: 40px;
  width: 100%;
  display: block;
}

.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--open .select2-selection--single {
  border: 1px solid #80bdff !important;
}

.select2-selection__clear {
  display: none !important;
}

span.select2-selection.select2-selection--single {
  outline: none;
}

.select2 {
  width: 100% !important;
}

.input_validation_error {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: solid red 1px;
}

.variableWidth {
  width: 55%;
}

.daterangepicker {
  position: absolute;
}

.table-condensed {
  background-color: #ffffff;
  border-radius: 15px;
  border: solid #D2D6DE 1.5px;
  position: relative;
}

.drp-buttons {
  display: none;
}

@media (max-width: 1500px) {
  .variableWidth {
    width: 75%;
  }
}

@media (max-width: 1225px) {
  .variableWidth {
    width: 100%;
  }

  .card_title {
    font-size: 1.95rem;
  }

  .help-text.card_title_help {
    font-size: 0.95rem;
  }
}

@media (max-width: 830px) {
  .card_title {
    font-size: 1.95rem;
  }

  .help-text.card_title_help {
    font-size: 0.95rem;
  }
}

@media (max-width: 650px) {
  .card_title {
    font-size: 1.65rem;
  }

  .help-text.card_title_help {
    font-size: 0.80rem;
  }
}

@media (max-width: 575px) {
  .modal .modal-content {
    top: 20vh;
    width: 80%;
    height: auto;
    margin: auto;
  }
}

@media (max-width: 465px) {
  .flex_form_group {
    flex-direction: column;
  }

  .card_title {
    font-size: 1.65rem;
  }

  .help-text.card_title_help {
    font-size: 0.71rem;
  }

  .modal .modal-content {
    width: 97%;
    top: 10vh;
  }
}