.login-box.register_login_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  float: none;
  margin: auto;
  position: static;

  .login-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    img {
      width: 100%;
    }

    .register_join_text_box {
      position: relative;

      p {
        margin: 0;
      }

      .register_join_text {
        font-size: 0;
        font-weight: 700;
        font-stretch: normal;
        text-align: center;
        white-space: nowrap;

        span {
          font-size: 32px;
        }
      }

      .register_us_text {
        position: relative;
        bottom: 12px;
        font-weight: 700;
        font-size: 32px;
        color: transparent;
        text-align: center;
        white-space: nowrap;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #ffffff;
      }
    }
  }

  .login-box-body {
    margin-right: 3rem;
  }
}

.box-body {
  position: relative;
}

.password_requirements {
  width: 100%;
  background-color: #c2a5f6;
  padding: 8px;
  border: solid white 3px;
  border-radius: 8px;
  position: absolute;
  z-index: 100;

  li:first-child {
    color: black;
  }

  i {
    color: red;
  }
}

.check-password-item {
  color: red;
}

.register_submit_button {
  height: 40px;
  width: 100%;
}

@media (min-width: 1250px) {
  .login-box.register_login_box {
    .login-logo {
      flex-direction: row;
      justify-content: space-around;

      .register_join_text_box {
        top: 0;

        .register_us_text {
          position: static;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .login-box.register_login_box {
    .login-logo {
      img {
        width: 100%;
        max-width: 455px;
      }

      .register_join_text_box {
        .register_join_text {
          span {
            font-size: 50px;
          }
        }

        .register_us_text {
          font-size: 50px;
        }
      }
    }
  }
}

@media (min-width: 1550px) {
  .login-box.register_login_box {
    .login-logo {
      .register_join_text_box {
        right: 0;
      }
    }
  }
  .login-box-body {
    width: 40vw;
  }
}

@media (max-width: 1400px) {
  .login-logo img {
    display: none;
  }
}

@media (max-width: 1250px) {
  .login-box.register_login_box .login-logo {
    padding: 0 3.2rem;
  }
}

@media (max-width: 900px) {
  .login-box.register_login_box {
    flex-direction: column;

    .login-box-body {
      margin: 0 0 5rem 0;
      width: 75%;
    }

    .login-logo {
      margin: 5rem 0 3rem 0;
      width: 75%;
    }
  }
}

@media (max-width: 768px) {
  .login-box.register_login_box {

    .login-logo {
      margin: 0 1rem 0;

      img {
        width: 100%;
        max-width: 455px;
      }
    }
  }

  .login-box-body {
    margin: 3rem 1rem 2rem !important;
  }
  .register_submit_button {
    width: 43vw;
    min-width: 160px;
    margin: 1rem auto 2rem;
  }
}

@media (max-width: 590px) {
  .login-box.register_login_box {
    .login-logo {
      width: 90%;
      padding: 0;
    }

    .login-box-body {
      width: 90%;
    }
  }
}

